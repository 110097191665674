<template>
  <Container :title="$t('referrals.referFriend')">
    <v-row v-if="!allCriteriaAreMet">
      <v-col cols="12">
        <ReferFriendCriteriaList :investment-criteria="investmentCriteria"/>
      </v-col>
    </v-row>
    <v-row class="mb-2" align="stretch">
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card elevation="2" class="flex" :disabled="!allCriteriaAreMet">
          <v-card-title v-text="$t('referrals.inviteFriends')"/>
          <v-card-text>
            <p v-text="$t('referrals.inviteYourFriends')"/>
            <p v-text="$t('referrals.loveMonestro')"/>
            <p v-text="$t('referrals.share')"/>
            <section v-if="allCriteriaAreMet">
              <a href="#" v-text="referralLink"/>&nbsp;
              <v-icon small class="copy-link" @click="copyLink">mdi-content-copy</v-icon>
            </section>
            <section v-else v-text="referralLink"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card elevation="2" class="flex" :disabled="!allCriteriaAreMet">
          <v-card-title v-text="$t('referrals.sendEmail')"/>
          <v-card-text class="py-0">
            <v-text-field
              v-model="email"
              filled
              required
              :rules="emailRules"
              :label="$t('referrals.email')"
            />
            <v-text-field
              v-model="emailText"
              filled
              required
              :label="$t('referrals.emailContent')"
            />
          </v-card-text>
          <v-card-actions class="px-4 pt-0 pb-4">
            <v-btn dark class="px-4" :loading="loading" @click="sendInvite" v-text="$t('referrals.sendInvite')"/>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="d-flex flex-column">
        <v-card elevation="2" class="flex" :disabled="!allCriteriaAreMet" :loading="sentLoading">
          <v-card-title>
            {{ $t('referrals.referAFriendPerformance') }}
            <v-spacer/>
            <v-tooltip bottom max-width="400">
              <template #activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ $t('referrals.helptext') }}</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text :loading="sentLoading">
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr>
                    <td v-text="$t('referrals.sentEmails')"/>
                    <td>{{ items.invitations_sent }} / {{ items.invitations_limit }}</td>
                  </tr>
                  <tr>
                    <td v-text="$t('referrals.referralAccounts')"/>
                    <td v-text="items.referred_accounts"/>
                  </tr>
                  <tr>
                    <td v-text="$t('referrals.upcomingPayments')"/>
                    <td><BaseMoney :amount="items.upcoming_payments || 0"/></td>
                  </tr>
                  <tr>
                    <td v-text="$t('referrals.youHaveEarned')"/>
                    <td><BaseMoney :amount="items.you_have_earned || 0"/></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </Container>
</template>

<script>
import Vue from 'vue'
import ReferFriendCriteriaList from '../components/ReferFriendCriteriaList.vue'
import { EventBus } from '@/wsupdate/eventbus'

export default Vue.extend({
  name: "ReferFriend",
  components: { ReferFriendCriteriaList },
  data() {
    return {
      items: {},
      loading: false,
      sentLoading: false,
      email: '',
      emailText: this.$t('referrals.iAmEarning'),
      emailRules: [
        (v) => !!v || this.$t('errors.emailIsRequired'),
        (v) =>
          new RegExp(
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$", // `'
          ).test(v) || this.$t('errors.notCorrectEmail'),
      ],
    }
  },
  computed: {
    account() {
      return this.$store.state.account
    },
    investmentCriteria() {
      return [
        { name: 'approvedConstraint', value: this.account.is_approved },
        { name: 'assignmentConstraint', value: this.account.is_confirmed_assignment },
        { name: 'verifiedConstraint', value: this.$auth.user().verification === 'approved' },
      ]
    },
    allCriteriaAreMet() {
      return this.investmentCriteria.map(c => c.value).every(el => el)
    },
    referralLink() {
      return this.allCriteriaAreMet ? `https://new.monestro.com/register?ref=${this.account.reference_number}` : this.$t('referrals.linkNotAvailable')
    },
  },
  watch: {
    account: {
      immediate: true,
      handler(value, oldValue) {
        if (value?.id != oldValue?.id) {
          this.getPerformance()
        }
      },
    },
    "$i18n.locale": {
      immediate: true,
      handler() {
        this.emailText = this.$t('referrals.iAmEarning')
      },
    },
  },
  mounted() {
    EventBus.$on('update-referrals', this.getPerformance)
  },
  unmounted() {
    EventBus.$off('update-referrals', this.getPerformance)
  },
  methods: {
    getPerformance() {
      this.sentLoading = true
      this.$http
        .get(`/referrals/performance/?investment_account_id=${this.account.id}`)
        .then((response) => {
          this.sentLoading = false
          this.items = response.data
        })
        .catch(() => {
          this.sentLoading = false
        })
    },
    sendInvite() {
      this.loading = true
      this.$http
        .post(`/referrals/send_invite/`, {
          recipient: this.email,
          referral_link: this.referralLink,
          message: this.emailText,
          investment_account_id: this.account.id,
        })
        .then(() => {
          this.loading = false
          this.$store.commit('pushMessage', {
            icon: 'mdi-check',
            text: this.$t('referrals.messageSuccess'),
          })
        })
        .catch((err) => {
          this.loading = false
          this.$store.commit('pushMessage', {
            icon: 'mdi-alert',
            text: this.$t(`referrals.${err.response.data.code}`),
          })
        })
    },
    copyLink() {
      navigator.clipboard.writeText(this.referralLink)
    },
  },
})
</script>

<style scoped>
  .copy-link:hover {
    cursor: pointer;
  }
</style>
