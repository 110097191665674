<template>
  <v-card>
    <v-list subheader flat disabled>
      <v-subheader>{{ $t('referrals.referringUnavailableHeader') }}</v-subheader>
      <v-list-item-group>
        <v-list-item v-for="criterion in investmentCriteria" :key="criterion.name">
          <template #default>
            <v-list-item-icon class="mr-2">
              <v-icon v-if="criterion.value" color="success">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-radiobox-blank</v-icon>
            </v-list-item-icon>
            <v-list-item-content><v-list-item-title v-text="$t(`referrals.${criterion.name}`)"/></v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "ReferFriendCriteriaList",
  props: {
    investmentCriteria: { type: Array, required: true},
  },
})
</script>
